
/* Text */
body {
  margin: 0;
  font-family: 'Inconsolata', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: block;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-weight: 700;
  background-color: black;
  color: white;
  padding: 6px 12px;
  font-size:1.5rem;
}

h2 {

}

h4 {
  font-weight: 700;
}

p {
  font-size: 1.25rem;
  line-height: 110%;
}

.subtitle {
  font-size: 0.75rem;

}

a {
  color: #000000;
}

/* Misc. CSS */ 
.constrained {
  max-width:750px;
  margin:auto;
}

.icon_and_text > img, .icon_and_text > h4 {
  display: inline-block;
}

.icon_and_text > img {
  height: 1.1rem;
  width: 1.1rem;
  margin-right:.5rem;
  margin-top:6px;
  vertical-align:top;
}

